<template>
  <base-layout
    :filter-button="false"
    :back-button="$vuetify.breakpoint.mdAndDown"
    :show-drawer-left="false"
    :show-drawer-right="false"
  >
    <template v-slot:main-content>
      <div v-if="agenda">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-subheader class="text-subtitle-1">
                <div class="d-flex justify-start">
                  {{ agenda.type }}
                </div>
                <v-spacer />
                <div class="justify-end">
                  <v-tooltip
                    bottom
                    open-delay="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="downloadXlsx"
                        icon
                        small
                        color="teal lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('agenda.downloadAsXLSX') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    open-delay="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="createAndDownloadICS"
                        icon
                        small
                        fab
                        color="teal lighten-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-calendar-export</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('agenda.exportOutlook') }}</span>
                  </v-tooltip>
                  <document-links
                    :link="agenda.url"
                    :source-title="agenda.title"
                    :listModuleSupport="false"
                    :type="agenda.type"
                  />
                </div>
              </v-subheader>
              <v-card>
                <v-card-title v-if="agenda.title">
                  <div v-html="agenda.title" />
                </v-card-title>
                <v-card-text class="text-body-2 mt-n3">
                  <v-card>
                    <v-card-text>
                      <div>
                        <div
                          v-if="agenda.url"
                          class="my-1"
                        >
                          <div class="list-subheader d-inline-block">
                            {{ $t('generic.source')}}
                          </div>
                          <div class="d-inline-block">
                            <a :href="agenda.url" target="_blank">{{ computedDomainify }}</a>
                          </div>
                        </div>

                        <div
                          v-if="dateFormatted"
                          class="my-1"
                        >
                          <div class="list-subheader d-inline-block">
                            {{ $t('generic.date')}}
                          </div>
                          <div class="d-inline-block">
                            {{ dateFormatted }}
                          </div>
                        </div>

                        <div
                          v-if="agenda.startTime || agenda.endTime"
                          class="my-1"
                        >
                          <div class="list-subheader d-inline-block">
                            {{ $t('generic.time')}}
                          </div>
                          <div
                            v-if="!agenda.startTime || agenda.startTime === agenda.endTime"
                            class="d-inline-block"
                          >
                            {{ $t('generic.to') }} {{ endTimeFormatted }}
                          </div>
                          <div
                            v-else-if="!agenda.endTime"
                            class="d-inline-block"
                          >
                            {{ startTimeFormatted }}
                          </div>
                          <div
                            v-else
                            class="d-inline-block"
                          >
                            {{ startTimeFormatted }} - {{ endTimeFormatted }}
                          </div>
                        </div>

                        <div
                          v-if="agenda.location"
                          class="my-1"
                        >
                          <div class="list-subheader d-inline-block">
                            {{ $t('generic.location') }}
                          </div>
                          <div class="d-inline-block">
                            {{ agenda.location }} <span v-if="agenda.confidentiality">
                              <confidentiality-status :confidentiality="agenda.confidentiality" />
                            </span>
                          </div>
                        </div>

                        <div
                          v-if="agenda.commission"
                          class="my-1"
                        >
                          <div class="list-subheader d-inline-block">
                            {{ $t('agenda.commission') }}
                          </div>
                          <div class="d-inline-block">
                            {{ agenda.commission }}
                          </div>
                        </div>

                        <div
                          class="d-flex justify-start my-1"
                          v-if="agenda.attachments && agenda.attachments.length > 0"
                        >
                          <div class="list-subheader d-inline-block">
                            {{ agenda.attachments.length > 1 ? $t('generic.attachment.plural') : $t('generic.attachment.singular') }}
                          </div>
                          <div class="list-downloads d-inline-block">
                            <ul class="d-inline-block" style="list-style-type: none; padding: 0; margin: 0;">
                              <li
                                v-for="(attachment, index) in agenda.attachments"
                                :key="index"
                              >
                                <a
                                  :href="attachment.fileUrl"
                                  rel="external"
                                  target="_blank"
                                >
                                  <span
                                    :class="{'matching': attachment.isMatching }"
                                    v-html="attachment.title"
                                  />
                                  <v-icon
                                      small
                                      class="mr-1"
                                  >mdi-download</v-icon>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>

                <v-card-text>
                  <div class="agenda-items">
                    <div v-if="agenda.startTime || agenda.endTime" class="agenda-item">
                      <div
                        class="agenda-item__index
                        agenda-item__index--time
                        agenda-item__index--time--start"
                      >
                        {{ agenda.startTime ? startTimeFormatted : endTimeFormatted }}
                      </div>
                    </div>

                    <article
                      class="agenda-item"
                      v-for="(agendaItem, index) in agenda.items"
                      :key="index"
                    >
                      <div class="agenda-item__index" :id="'agenda-item-' + (index + 1)">
                        {{ index + 1 }}
                      </div>

                      <div class="agenda-item__content">
                        <h3
                          class="my-2"
                          v-html="agendaItem.title"
                        />
                        <div
                          v-for="(kase, index) in agendaItem.cases"
                          :key="index"
                          class="ml-4"
                        >
                          <h4 class="mb-1">• {{ kase.dossier ? kase.dossier : kase.entityCode }} {{ kase.title }}</h4>
                          <div class="">
                            <ul v-if="kase.decisions?.length" class="mb-1">
                              <li
                                v-for="(decision, index) in kase.decisions"
                                class="my-1"
                                :key="index"
                              >
                                <v-icon class="mr-1" small> mdi-gavel </v-icon>
                                <span>Status:&nbsp;</span>
                                <span class="font-weight-bold" :style="voteResultStatusStyling(decision.text)">
                                  {{ decision.text }}
                                </span>
                              </li>
                            </ul>
                            <ul v-if="kase.documents?.length" class="mb-1">
                              <li
                                class="my-1"
                                v-for="(document, index) in kase.documents"
                                :key="index"
                              >
                                <a
                                  :href="document.fileUrl"
                                  rel="external"
                                  target="_blank"
                                >
                                  <v-icon class="mr-1" small> mdi-file-document </v-icon>
                                  <span :class="{'matching': document.isMatching }" v-html="document.title"/>
                                </a>
                                <span style="white-space: nowrap;">
                                  ({{ $t('generic.source') }}:
                                  <a :href="document.sourceUrl" target="_blank">Tweede Kamer</a>
                                  <span> / </span>
                                  <a :href="document.fileUrl" target="_blank">Polpo</a>
                                  )
                                </span>

                                <ul class="mb-2">
                                  <li
                                    v-for="(attachment, index) in document.attachments"
                                    :key="index"
                                  >
                                    <a :href="attachment.fileUrl" rel="external">
                                      <v-icon class="mr-1" small> mdi-download </v-icon>
                                      {{ attachment.title }}
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </article>
                    <div v-if="agenda.endTime" class="agenda-item">
                      <div
                        class="agenda-item__index
                        agenda-item__index--time
                        agenda-item__index--time--end"
                      >
                        {{ endTimeFormatted }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </base-layout>
</template>

<script>
import moment from 'moment';
import { saveAs } from 'file-saver';
import { mapGetters } from 'vuex';
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import DocumentLinks from '@/components/DocumentLinks.vue';
import ConfidentialityStatus from '@/components/Confidentiality.vue';
import domainify from '@/helpers/domainifyHelper';
import { stripHtml } from '@/helpers/toolbelt';
import { voteResultStatusStyling } from '@/helpers/voteResultsHelper';

export default {
  name: 'AgendaDetail',
  components: {
    BaseLayout,
    DocumentLinks,
    ConfidentialityStatus,
  },

  data() {
    return {
      backButton: true,
      bookmarks: [],
    };
  },

  computed: {
    ...mapGetters({
      agenda: 'agendaCurrent',
    }),
    computedDomainify() {
      return domainify(this.agenda.url);
    },
    agendaId() {
      return this.$route.params.agendaId;
    },
    dateFormatted() {
      return this.$moment(this.agenda.date || this.agenda.startTime || this.agenda.endTime).format('dddd D MMMM YYYY'); // .fromNow()
    },
    startTimeFormatted() {
      return this.$moment(this.agenda.startTime).format('H:mm'); // .fromNow()
    },
    endTimeFormatted() {
      return this.$moment(this.agenda.endTime).format('H:mm'); // .fromNow()
    },
  },

  methods: {
    voteResultStatusStyling,

    // Helper function for appending CRLF at start and end of file according to RFC rules.
    addCRLF(string) {
      return `\n${string}\n`;
    },

    createAndDownloadICS() {
      // TODO: in location we added static 'Tweede Kamer'. When more sources become
      // active we have to change this into a variable.
      // TODO: We have to modify the 'description' field after 'origin=api'
      // has become leading and default (See POLMONNL-2230)
      const Calendar = this.addCRLF(`
        BEGIN:VCALENDAR
        PRODID:-//Polpo//NONSGML Event Calendar//NL
        VERSION:2.0
        BEGIN:VEVENT
        X-WR-CALNAME:Polpo
        X-WR-RELCALID:1234
        UID:${this.agendaId}@polpo.nl
        DESCRIPTION:${window.location.origin}${this.$route.path}
        DTSTAMP;VALUE=DATE-TIME:${moment(new Date().getTime()).format('YYYYMMDDTHHmmss')},
        ${(this.agenda.startTime || this.agenda.endTime)
    ? `DTSTART;VALUE=DATE-TIME:${moment(this.agenda.startTime || this.agenda.endTime).format('YYYYMMDDTHHmmss')}
            DTEND;VALUE=DATE-TIME:${this.agenda.type === 'Plenair debat'
          && this.agenda.startTime && this.agenda.endTime
          && new Date(this.agenda.startTime) > new Date(this.agenda.endTime)
    ? `${moment(this.agenda.startTime).format('YYYYMMDDTHHmmss')}`
    : `${moment(this.agenda.endTime || this.agenda.startTime).format('YYYYMMDDTHHmmss')}`}`
    : `DTSTART;VALUE=DATE:${moment(this.agenda.date).format('YYYYMMDD')}` // All Day Event
}
        LOCATION:${this.agenda.commission
    ? `${this.agenda.commission} -`
    : 'Plenair debat -'} Tweede Kamer - ${this.agenda.location} ${this.agenda.confidentiality
  ? `(${this.agenda.confidentiality})`
  : ''}
        SUMMARY;LANGUAGE=nl:${this.agenda.type} - ${stripHtml(this.agenda.title)}
        END:VEVENT
        END:VCALENDAR
      `.replace(/^\s*[\r\n]/gm, '').replace(/^\s+/gm, ''));
      const blob = new Blob([Calendar], { type: 'text/x-vCalendar;charset=utf-8' });
      const filename = `${this.agendaId}-polpo`;
      saveAs(blob, `${filename}.ics`);
    },

    async downloadXlsx() {
      const response = await this.$store.dispatch('getAgendaXlsxById', { id: this.agendaId });
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const filename = `${stripHtml(this.agenda.title).replace(/[/\\?%*:|"<>]/g, '').trim()}`;
      saveAs(blob, `${filename}.xlsx`);
    },
  },

  created() {
    if (this.agendaId) {
      this.$store.dispatch('getAgendaById', {
        id: this.agendaId,
        query: this.$route.query.query,
        origin: this.$route.query.origin,
      });
    } else {
      this.$router.push({ name: 'nlDashboard' })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('route', err);
        });
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.fullPath === '/') {
        vm.$store.dispatch('setReferrer', false);
      }
    });
  },

  mounted() {
    this.$gtag.pageview({
      page_path: `/agenda/${this.agendaId}`,
    });
  },

};
</script>

<style scoped lang="scss">
// Default colors, feel free to override in _colors.scss
$color-agenda-item: #607d8b !default;

.matching,
em.hlt1 {
  background-color: $color-marker;
  padding: 2px 4px;
  color: white;
  font-style: normal;
}

.list-subheader {
  min-width: 78px;
}

ul {
  list-style-type: none;
  li {
    //margin-left: -25px;
    a {
      text-decoration: none;
    }
  }
}

.agenda-item {
  display: flex;

  //&__title {
  //  //line-height: 1.1em;
  //  margin-top: -2px;
  //  color: #666;
  //  //font-size: 1em;
  //  margin-bottom: 1em;
  //}

  &__index {
    font-size: 18px;
    float: left;
    width: 60px;
    height: 32px;
    color: white;
    background-color: $color-agenda-item;
    padding: 5px;
    line-height: 20px;
    border-radius: 5px;
    text-align: center;
    flex: 0 0 60px;
    z-index: 2;
    // margin-left: -10px;

    &--time {
      font-size: 18px;
      flex: 0 0 60px;
      height: 31px;
      line-height: 19px;
      background-color: white;
      color: $color-agenda-item;
      border: 1px solid $color-agenda-item;

      &--start {
        margin-bottom: 35px;
        &:after {
          content: '';
          height: 35px;
          display: block;
          border-left: 1px dotted $color-agenda-item;
          margin-left: 25px;
          margin-top: 5px;
        }
      }
    }
  }

  &__content {
    padding-bottom: 15px;
    padding-left: 50px;
    border-left: 1px dotted $color-agenda-item;
    flex: 1 0 0;
    margin-left: -30px;
  }

}

.link {
  &__icon {
    transform: scale(0.95);
    position: relative;
    flex: 0 0 16px;
    top: 3px;
    margin-right: 6px;
  }
}

</style>

<style lang="scss"> // Careful, block not scoped
.matching,
em.hlt1 {
  background-color: $color-marker;
  padding: 2px 4px;
  color: white;
  font-style: normal;
}
</style>
